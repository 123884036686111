<template>
  <div id="host-profile-page" class="page">
    <h1 class="page-header">
      <span class="page-header__text">마이페이지</span>
      <button type="button" class="page-header__button" @click="logout">로그아웃</button>
    </h1>
    <div class="page-body">
      <div class="form-body">
        <ui-form @submit="submitForm" @error="scrollToError" :formData="formData" ref="form">
          <ui-form-list>
            <ui-form-list-item>
              <profile-uploader v-model="formData.profile_photo.value" />
            </ui-form-list-item>
            <ui-form-list-item>
              <ui-textbox
                :placeholder="formData.user_name.placeholder"
                :type="formData.user_name.type"
                :label="formData.user_name.label"
                :error="formData.user_name.error"
                v-model="formData.user_name.value"
              />
            </ui-form-list-item>

            <ui-form-list-multiple-item>
              <ui-textbox
                :placeholder="formData.user_first_name.placeholder"
                :type="formData.user_first_name.type"
                :label="formData.user_first_name.label"
                :error="formData.user_first_name.error"
                v-model="formData.user_first_name.value"
              />
              <ui-textbox
                :placeholder="formData.user_last_name.placeholder"
                :type="formData.user_last_name.type"
                :label="formData.user_last_name.label"
                :error="formData.user_last_name.error"
                v-model="formData.user_last_name.value"
              />
            </ui-form-list-multiple-item>

            <ui-form-list-item>
              <!-- <ui-select :placeholder="formData.user_iso_code.placeholder" :items="formData.user_iso_code.items" :label="formData.user_iso_code.label" :error="formData.user_iso_code.error" v-model="formData.user_iso_code.value" /> -->
              <ui-auto-complete
                :placeholder="formData.user_iso_code.placeholder"
                :label="formData.user_iso_code.label"
                :error="formData.user_iso_code.error"
                :items="formData.user_iso_code.items"
                v-model="formData.user_iso_code.value"
                type="iso"
              ></ui-auto-complete>
            </ui-form-list-item>

            <ui-form-list-multiple-item>
              <!-- <ui-select :placeholder="formData.user_dial_code.placeholder" :items="formData.user_dial_code.items" :label="formData.user_dial_code.label" :error="formData.user_dial_code.error" v-model="formData.user_dial_code.value" :displayValue="true" /> -->
              <ui-auto-complete
                :placeholder="formData.user_dial_code.placeholder"
                :label="formData.user_dial_code.label"
                :error="formData.user_dial_code.error"
                :items="formData.user_dial_code.items"
                v-model="formData.user_dial_code.value"
                type="dial"
              ></ui-auto-complete>
              <ui-textbox
                :placeholder="formData.user_phone.placeholder"
                :type="formData.user_phone.type"
                :label="formData.user_phone.label"
                :error="formData.user_phone.error"
                v-model="formData.user_phone.value"
                maxLength="15"
              />
            </ui-form-list-multiple-item>

            <ui-form-list-item :error="formData.user_email.error">
              <ui-textbox
                :placeholder="formData.user_email.placeholder"
                :type="formData.user_email.type"
                :label="formData.user_email.label"
                :error="formData.user_email.error"
                v-model="formData.user_email.value"
              />
            </ui-form-list-item>
          </ui-form-list>
        </ui-form>
      </div>
    </div>
    <div class="page-footer">
      <button type="button" class="ui-submit-button activated" @click.prevent="$refs.form.submitForm">
        <span class="ui-submit-button__text">{{ $__t("프로필 저장하기") }}</span>
      </button>
    </div>
  </div>
</template>

<script>
import ProfileUploader from '@/components/modules/RichForm/ProfileUploader';
import countryCodes from '@/assets/json/country-codes.json';
import AutoComplete from '@trevoreyre/autocomplete-vue';

export default {
  data() {
    var _code = countryCodes.map((o) => {
      return { name: o.name, code: o.code };
    });
    _code.sort((a, b) => {
      return a.code.toUpperCase() < b.code.toUpperCase() ? -1 : a.code.toUpperCase() > b.code.toUpperCase() ? 1 : 0;
    });
    _code.forEach((o) => {
      if (o.name === 'Korea, Republic of') {
        _code.splice(_code.indexOf(o), 1);
        _code.unshift(o);
      }
      if (o.name === 'Others') {
        _code.splice(_code.indexOf(o), 1);
        _code.push(o);
      }
    });

    var _dial_code = countryCodes.map((o) => {
      return { name: o.name, code: o.dial_code };
    });
    _dial_code.sort((a, b) => {
      return a.code < b.code ? -1 : a.code > b.code ? 1 : 0;
    });
    _dial_code.forEach((o) => {
      if (o.name === 'Korea, Republic of') {
        _dial_code.splice(_dial_code.indexOf(o), 1);
        _dial_code.unshift(o);
      }
      if (o.name === 'Others') {
        _dial_code.splice(_dial_code.indexOf(o), 1);
        _dial_code.push(o);
      }
    });

    var formData = {
      profile_photo: {
        value: {},
        error: '',
      },
      user_name: {
        type: 'text',
        label: this.$__t('별명'),
        placeholder: this.$__t('별명을 입력해주세요'),
        validation: [{ type: 'required' }],
        value: '',
        error: '',
      },
      user_last_name: {
        type: 'text',
        label: this.$__t('성'),
        placeholder: this.$__t('Korean or English'),
        validation: [{ type: 'required' }, { type: 'koreanOrEnglish' }],
        value: '',
        error: '',
      },
      user_first_name: {
        type: 'text',
        label: this.$__t('이름'),
        placeholder: this.$__t('Korean or English'),
        validation: [{ type: 'required' }, { type: 'koreanOrEnglish' }],
        value: '',
        error: '',
      },
      user_iso_code: {
        label: this.$__t('국가'),
        placeholder: this.$__t('국가를 선택해주세요'),
        validation: [{ type: 'required' }],
        value: '',
        items: _code.map((o) => {
          return { label: o.code + ' (' + o.name + ')', value: o.code };
        }),
        error: '',
      },
      user_dial_code: {
        label: this.$__t('국가 번호'),
        placeholder: this.$__t('국가 번호를 선택해주세요'),
        validation: [{ type: 'required' }],
        value: '',
        items: _dial_code.map((o) => {
          return { label: o.code + ' (' + o.name + ')', value: o.code };
        }),
        error: '',
      },
      user_phone: {
        type: 'text',
        label: this.$__t('휴대폰 번호'),
        placeholder: this.$__t('휴대폰 번호를 입력해주세요'),
        validation: [{ type: 'required' }, { type: 'number' }],
        value: '',
        error: '',
      },
      user_email: {
        type: 'text',
        label: this.$__t('이메일 주소'),
        placeholder: this.$__t('이메일 주소를 입력해주세요'),
        validation: [{ type: 'required' }, { type: 'email' }],
        value: '',
        error: '',
      },
    };
    return {
      formData,
    };
  },

  mounted() {
    this.loadAuthInfo();
  },

  methods: {
    loadAuthInfo() {
      this.formData.user_email.value = this.$store.state.auth.user_email;
      this.formData.user_name.value = this.$store.state.auth.user_name;
      this.formData.user_phone.value = this.$store.state.auth.user_phone;
      this.formData.user_first_name.value = this.$store.state.auth.user_first_name;
      this.formData.user_last_name.value = this.$store.state.auth.user_last_name;
      this.formData.user_phone.value = this.$store.state.auth.user_phone;
      this.formData.profile_photo.value = {
        resource_url: this.$store.state.auth.profile_image,
      };
      this.formData.user_dial_code.value = this.$store.state.auth.user_dial_code;
      let item = this.formData.user_iso_code.items.find((o) => o.value == this.$store.state.auth.user_iso_code);
      this.formData.user_iso_code.value = item ? item.label : this.$store.state.auth.user_iso_code;
    },

    checkName(values) {
      var regType = /^[가-힣|A-Za-z]*$/;
      var error = null;

      if (!values.user_first_name) {
        error = new Error(this.$__t('Name in Korean or English'));
        this.$data.formData.user_first_name.error = error.message;
      } else if (!regType.test(values.user_first_name)) {
        error = new Error(this.$__t('Name in Korean or English'));
        this.$data.formData.user_first_name.error = error.message;
      } else {
        this.$data.formData.user_first_name.error = null;
      }

      if (!values.user_last_name) {
        error = new Error(this.$__t('Name in Korean or English'));
        this.$data.formData.user_last_name.error = error.message;
      } else if (!regType.test(values.user_last_name)) {
        error = new Error(this.$__t('Name in Korean or English'));
        this.$data.formData.user_last_name.error = error.message;
      } else {
        this.$data.formData.user_last_name.error = null;
      }

      return error;
    },

    submitForm(values) {
      this.loading = true;

      if (!this.formData.user_iso_code.items.some((e) => e.label === values.user_iso_code)) {
        this.formData.user_iso_code.error = this.$__t('Please select a existing value among the list.');
      }
      if (!this.formData.user_dial_code.items.some((e) => e.value === values.user_dial_code)) {
        this.formData.user_dial_code.error = this.$__t('Please select a existing value among the list.');
      }
      if (this.formData.user_dial_code.error || this.formData.user_iso_code.error) {
        this.loading = false;
        return;
      }

      values.user_iso_code = values.user_iso_code.substring(0, values.user_iso_code.indexOf(' ('));

      let error = this.checkName(values);
      if (error) {
        this.loading = false;
        return;
      }

      try {
        let res = this.$store.dispatch('auth/edit', values);
        this.$store.commit('alert/ADD_ITEM', { message: this.$__t('프로필이 수정되었습니다.'), status: 'success' });
      } catch (e) {
        this.$store.commit('alert/ADD_ITEM', { message: this.$__t(e), status: 'error' });
      } finally {
        this.loading = false;
      }
    },
    applyPromoter() {
      Object.keys(this.formData).forEach((o) => {
        this.$refs.form.validateField(this.formData[o]);
      });
      if (this.$refs.form.existError) this.scrollToError();

      let interval = setInterval(() => {
        if (!this.loading) {
          clearInterval(interval);
          if ($('.ui-form-list-item .error').length > 0) {
            return;
          }

          this.axios
            .get(this.$store.state.config.apiURL + '/v2/campaigns/invite', {
              headers: this.$store.state.config.userHeaders,
            })
            .then(({ data }) => {
              this.campaign_code = data.data.campaign_code;
              this.isShowLink = true;
            })
            .catch((error) => {
              this.$store.commit('alert/ADD_ITEM', { message: error, status: 'error' });
            });
        }
      }, 300);
    },
    showModal() {
      this.isShowModal = true;
    },
    closeModal() {
      this.isShowModal = false;
    },
    copyToClipboard(id) {
      var copyText = document.getElementById(id);

      copyText.select();
      copyText.setSelectionRange(0, 99999); /*For mobile devices*/

      document.execCommand('copy');

      alert(this.$__t('Copied to clipboard'));
    },
    serializeFields() {
      var values = {};
      Object.keys(this.formData).forEach((o) => {
        if (typeof this.formData[o].value == 'undefined') {
          values[o] = this.formData[o].value;
        } else if (typeof this.formData[o].value == 'array' || typeof this.formData[o].value == 'object') {
          values[o] = JSON.stringify(this.formData[o].value);
        } else {
          if (typeof this.formData[o].value == 'boolean') {
            values[o] = this.formData[o].value;
          } else {
            values[o] = this.formData[o].value.toString();
          }
        }
      });
      return values;
    },
    scrollToError() {
      setTimeout(() => {
        const errorList = document.querySelectorAll('.ui-form-list-item div.error');
        if (errorList.length >= 0) {
          const elOffset = $(errorList[0]).offset().top;
          const elHeight = $(errorList[0]).height();
          const windowHeight = $(window).height();

          let offset;

          if (elHeight < windowHeight) {
            offset = elOffset - (windowHeight / 2 - elHeight / 2);
          } else {
            offset = elOffset;
          }

          $('html, body').animate({ scrollTop: offset }, 200);
        }
      }, 0);
    },

    logout() {
      this.$store.dispatch('auth/logout');
      this.$store.commit('SET_TRUE', { key: 'appIntroViewed' });
    },
  },

  components: {
    AutoComplete,
    ProfileUploader,
  },
};
</script>

<style lang="scss" scoped>
#host-profile-page {
  .page-header {
    padding: unit(20) unit(16);
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    &__text {
      font-size: unit(22);
      font-weight: bold;
      line-height: 1.5;
    }

    &__button {
      flex: 0;
      cursor: pointer;
      text-decoration: underline;
      font-size: unit(14);
      color: #a9afb3;
    }
  }

  .page-body {
    padding: 0 unit(16) unit(80) unit(16)
  }

  .page-footer {
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
    height: unit(80);
    padding: unit(16);
  }
}
</style>
